import { BrowserRouter } from "react-router-dom";
import "./internals/css/astconstruction.css";
import "./internals/css/responsive.css";
import Header from "./internals/pages/Header";
import Footer from "./internals/pages/Footer";
import PageRoute from "./internals/pages/pageRoute";
import "animate.css/animate.min.css";
import React, { useState, useEffect } from "react";

function App() {
  const [loadTime, setLoadTime] = useState(1);
  useEffect(() => {
    setTimeout(() => {
      showPage();
    }, 1000);
  });

  function showPage() {
    setLoadTime(3);
  }
  if (loadTime == 1) {
    return <div id="loader"></div>;
  }
  return (
    <>
      <div id="wholePage">
        <BrowserRouter>
          <Header />
          <PageRoute />
          <Footer />
        </BrowserRouter>
      </div>
    </>
  );
}

export default App;

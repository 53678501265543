import { Routes, Route } from "react-router-dom";
import Home from "./Home";
import Blogs from "./Blogs";
import Contact from "./Contact";
import Aboutus from "./Aboutus";
import Services from "./Services";
import Gallery from "./Gallery";
import NoPage from "./NoPage";

export default function pageRoute() {
  return (
    <>
      <Routes>
        <Route index element={<Home />} />
        <Route path="/aboutus" element={<Aboutus />} />
        <Route path="/blogs" element={<Blogs />} />
        <Route path="/gallery" element={<Gallery />} />
        <Route path="/services" element={<Services />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="*" element={<NoPage />} />
      </Routes>
    </>
  );
}

import buildingDream from "../images/logo-ast-construction.png";
import { Helmet } from "react-helmet";
export default function Contact() {
  return (
    <>
      <Helmet>
        <title>Contact Us | AST Constructions</title>
        <meta
          name="description"
          content="AST Constrctions a name recognized as a pioneer in construction and the development projects in Canada. We have a unique business model, with proven expertise and innovative thinking, project and cost management. We are focused on delivering high-quality work within budgeted time and cost, as evident in the various accolades and repeat business."
        />
        <meta
          name="keywords"
          content="AST Construction, Contact Us, Building , Basement, kitchen, bathroom, construction "
        />
        <meta name="author" content="Harleen Grover - Best Web Dealers" />
        <meta name="theme-color" content="#698EDC" />
        <meta name="msapplication-navbutton-color" content="#698EDC" />
        <meta name="apple-mobile-web-app-status-bar-style" content="#698EDC" />
        <meta
          property="og:description"
          content="AST Constrctions a name recognized as a pioneer in construction and the development projects in Canada. We have a unique business model, with proven expertise and innovative thinking, project and cost management. We are focused on delivering high-quality work within budgeted time and cost, as evident in the various accolades and repeat business."
        />
        <meta property="og:site_name" content="" />
        <meta property="og:title" content="Contact Us | AST Construction" />
        <meta property="og:image" content={buildingDream} />
        <meta name="csrf-token" content="" />
        <meta name="msapplication-TileColor" content="#698EDC" />
        <meta name="msapplication-TileImage" content="" />
        <meta name="theme-color" content="#698EDC" />
        <meta http-equiv="cache-control" content="max-age=0" />
        <meta http-equiv="cache-control" content="no-cache" />
        <meta http-equiv="pragma" content="no-cache" />
      </Helmet>
      <div id="internal-banner">
        <div className="clr"></div>
        <div id="banner">
          <h5>.contact Us</h5>
        </div>
        <p>
          <div className="innerPageContent animateTop">
            For inquiries or to discuss your construction project requirements,
            please contact us at{" "}
            <a href="mailto:">amrik@astconstructions.com</a>.{" "}
            <p>
              Let AST Construction Limited be your partner in building a better
              future. At AST Construction Limited, we don't just construct
              buildings; we build relationships, trust, and a legacy of
              excellence that stands the test of time.
            </p>
          </div>
        </p>
        <form className="animateTop">
          <div className="innerPageContent">
            <div class="left xinhao">
              <div class="box">
                <div class="hover1">
                  .name<span>*</span>
                </div>
                <input
                  required
                  type="text"
                  class="text inp"
                  name="username"
                  id="username"
                  placeholder=""
                />
              </div>
              <div class="box">
                <div class="hover1">.company</div>
                <input
                  type="text"
                  class="text inp"
                  name="company"
                  id="company"
                  placeholder=""
                />
              </div>
              <div class="box">
                <div class="hover1">.phone</div>
                <input
                  type="text"
                  class="text inp"
                  tel="tel"
                  id="tel"
                  placeholder=""
                />
              </div>
              <div class="box">
                <div class="hover1">
                  .email<span>*</span>
                </div>
                <input
                  type="email"
                  class="text inp"
                  name="email"
                  id="email"
                  placeholder=""
                  required
                  email
                />
              </div>
              <div class="box">
                <div class="hover1">
                  .country<span>*</span>
                </div>
                <input
                  type="text"
                  class="text inp"
                  name="country"
                  id="country"
                  placeholder=""
                />
              </div>
              <div class="box">
                <div class="hover1">
                  .city<span>*</span>
                </div>
                <input
                  type="text"
                  class="text inp"
                  name="city"
                  id="city"
                  placeholder=""
                />
              </div>
            </div>
            <div class="right xinhao">
              <div class="box">
                <div class="hover1">
                  .message<span>*</span>
                </div>
                <textarea
                  placeholder=""
                  class="inp"
                  name="content"
                  id="content"
                  required
                ></textarea>
              </div>
            </div>
            <div className="clr"></div>
            <div>
              <input type="submit" value="SUBMIT" />
              <input type="reset" value="RESET" />
            </div>
          </div>
        </form>
      </div>
    </>
  );
}

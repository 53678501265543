import React, { useEffect, useState } from "react";
import { Outlet, Link } from "react-router-dom";
import axios from "axios";
import mobile_nav from "../images/svg_banners/nav-icon.svg";
export default function Nav() {
  const [navActive, setNavActive] = useState("navbar");
  const [nav, setnav] = useState([]);

  useEffect(() => {
    axios
      .get(
        `https://www.bestwebdealers.com/astconstructions/api.php?control=getNavs`,
        { crossdomain: true },
        {
          headers: {
            "Access-Control-Allow-Origin": "*",
            "Content-Type": "application/json",
          },
        }
      )
      .then((res) => {
        console.log(res);
        setnav(res.data);
      });
  }, []);
  function closeNavFun() {
    setNavActive("navbar");
  }
  function openNavFun() {
    setNavActive("navbar open");
  }
  return (
    <>
      <div onClick={openNavFun} className="material-symbols-outlined">
        <img src={mobile_nav} alt="mobile navigation" />
      </div>
      <nav className={navActive}>
        <div onClick={closeNavFun} id="closeNav">
          X
        </div>
        <ul className="navbar-nav">
          <li className="nav-item active">
            {nav.map((na) => (
              <Link className="nav-link" to={na.link}>
                {na.name}
              </Link>
            ))}
          </li>
        </ul>
      </nav>
      <div class=".clr"></div>
    </>
  );
}

import "react-slideshow-image/dist/styles.css";
import { Slide } from "react-slideshow-image";
import ClientPic1 from "../images/client-pic.png";
import ClientPic from "../images/client-pic1.png";
import buildingMiddle from "../images/bulding-middle.png";
import Banner from "./Banner";
import buildingDream from "../images/building-dreams.png";
import { Helmet } from "react-helmet";
export default function Home() {
  var testimonials = [
    {
      pic: ClientPic,
      testi:
        "This Guy is a man of his words. Delivered services on time. Never made any excuse during construction work. He delivered quality products. I am so impressed with services delivered and had an amazing experience working with AST CONSTRUCTIONS LTD.",
    },
    {
      pic: ClientPic1,
      testi:
        " This guy did a tremendous job, from walls to floor and kitchen to washroom each and everything he did is commendable. loved his work. trustworthy guy!! always recommend",
    },
  ];
  return (
    <>
      <Helmet>
        <title>Home | AST Constructions</title>
        <meta
          name="description"
          content="AST Constrctions a name recognized as a pioneer in construction and the development projects in Canada. We have a unique business model, with proven expertise and innovative thinking, project and cost management. We are focused on delivering high-quality work within budgeted time and cost, as evident in the various accolades and repeat business."
        />
        <meta
          name="keywords"
          content="Building , Basement, kitchen, bathroom, construction "
        />
        <meta name="author" content="Harleen Grover - Best Web Dealers" />
        <meta name="theme-color" content="#698EDC" />
        <meta name="msapplication-navbutton-color" content="#698EDC" />
        <meta name="apple-mobile-web-app-status-bar-style" content="#698EDC" />
        <meta
          property="og:description"
          content="AST Constrctions a name recognized as a pioneer in construction and the development projects in Canada. We have a unique business model, with proven expertise and innovative thinking, project and cost management. We are focused on delivering high-quality work within budgeted time and cost, as evident in the various accolades and repeat business."
        />
        <meta property="og:site_name" content="" />
        <meta property="og:title" content="Home | AST Construction" />
        <meta property="og:image" content={buildingDream} />
        <meta name="csrf-token" content="" />
        <meta name="msapplication-TileColor" content="#698EDC" />
        <meta name="msapplication-TileImage" content="" />
        <meta name="theme-color" content="#698EDC" />
        <meta http-equiv="cache-control" content="max-age=0" />
        <meta http-equiv="cache-control" content="no-cache" />
        <meta http-equiv="pragma" content="no-cache" />
      </Helmet>
      <Banner />
      <div id="testimonial">
        <h1 id="module-title">meet our client</h1>
        <br />
        <Slide>
          <div className="each-slide-effect">
            <div id="picture">
              <img src={testimonials[0].pic} />
            </div>
            <div id="testi">{testimonials[0].testi}</div>
          </div>
          <div className="each-slide-effect">
            <div id="picture">
              <img src={testimonials[1].pic} />
            </div>
            <div id="testi">{testimonials[1].testi}</div>
          </div>
        </Slide>
        <div className="clr"></div>
      </div>
      <div id="we-here">
        <div id="text">
          <h1 id="module-title">we at ast construction</h1>
          <span data-aos="fade-up" data-aos-duration="1000">
            It is a long established fact that a reader will be distracted by
            the readable content of a page when looking at its layout. The point
            of using Lorem Ipsum is that it has a more-or-less normal
            distribution of letters, as opposed to using 'Content here, content
            here', making it look like readable English. Many desktop publishing
            packages and web page editors now use Lorem Ipsum as their default
            model text, and a search for 'lorem ipsum' will uncover many web
            sites still in their infancy. Various versions have evolved over the
            years, sometimes by accident, sometimes on purpose (injected humour
            and the like).
          </span>
        </div>

        <div id="pic">
          <img src={buildingMiddle} />
        </div>
        <div className="clr"></div>
      </div>
    </>
  );
}

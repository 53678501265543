import banner from "../images/aboutback.jpg";
import buildingDream from "../images/logo-ast-construction.png";
import { Helmet } from "react-helmet";
import establishmentPic from "../images/pic_establishment.png";
export default function Aboutus() {
  return (
    <>
      <Helmet>
        <title>About Us | AST Constructions</title>
        <meta
          name="description"
          content="AST Constrctions a name recognized as a pioneer in construction and the development projects in Canada. We have a unique business model, with proven expertise and innovative thinking, project and cost management. We are focused on delivering high-quality work within budgeted time and cost, as evident in the various accolades and repeat business."
        />
        <meta
          name="keywords"
          content="AST Construction, Building , Basement, kitchen, bathroom, construction "
        />
        <meta name="author" content="Harleen Grover - Best Web Dealers" />
        <meta name="theme-color" content="#698EDC" />
        <meta name="msapplication-navbutton-color" content="#698EDC" />
        <meta name="apple-mobile-web-app-status-bar-style" content="#698EDC" />
        <meta
          property="og:description"
          content="AST Constrctions a name recognized as a pioneer in construction and the development projects in Canada. We have a unique business model, with proven expertise and innovative thinking, project and cost management. We are focused on delivering high-quality work within budgeted time and cost, as evident in the various accolades and repeat business."
        />
        <meta property="og:site_name" content="" />
        <meta property="og:title" content="About Us | AST Construction" />
        <meta property="og:image" content={buildingDream} />
        <meta name="csrf-token" content="" />
        <meta name="msapplication-TileColor" content="#698EDC" />
        <meta name="msapplication-TileImage" content="" />
        <meta name="theme-color" content="#698EDC" />
        <meta http-equiv="cache-control" content="max-age=0" />
        <meta http-equiv="cache-control" content="no-cache" />
        <meta http-equiv="pragma" content="no-cache" />
      </Helmet>
      <div id="internal-banner">
        <div className="clr"></div>
        <div id="banner">
          <h5>.about us</h5>
        </div>
        <div className="innerPageContent animateTop" id="about-area">
          <strong>Establishment:</strong>
          <img src={establishmentPic} />
          <p>
            AST Construction Limited was established in August 2020 by Amreek, a
            visionary entrepreneur with a passion for excellence in the
            construction industry. With a firm commitment to quality,
            innovation, and client satisfaction, AST Construction Limited has
            quickly emerged as a leading player in the construction sector.
          </p>

          <p></p>
          <p></p>
          <strong>Our Vision:</strong>
          <p>
            At AST Construction Limited, our vision is to redefine the standards
            of construction excellence. We strive to be recognized as the
            preferred choice for clients seeking top-notch construction
            solutions, characterized by reliability, efficiency, and superior
            craftsmanship.
          </p>
          <p></p>
          <p></p>
          <strong>Our Mission:</strong>
          <p>
            Our mission is to deliver exceptional construction services tailored
            to meet the unique needs of each client. We aim to exceed
            expectations through innovative approaches, attention to detail, and
            a relentless pursuit of excellence in every project we undertake.
          </p>
          <p></p>
          <p></p>
        </div>
      </div>
    </>
  );
}

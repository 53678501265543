import explanationPic from "../images/middle-explamation.png";
import buildingDream from "../images/building-dreams.png";
export default function Banner() {
  return (
    <div id="banner">
      <div id="banner-part">
        <img src={explanationPic} />
      </div>
      <div id="banner-area">
        <img src={buildingDream} />
      </div>
      <div className="clr"></div>
    </div>
  );
}

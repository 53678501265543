import buildingDream from "../images/logo-ast-construction.png";
import { Helmet } from "react-helmet";
import ModalImage from "react-modal-image";
export default function Gallery() {
  return (
    <>
      <Helmet>
        <title>Gallery | AST Constructions</title>
        <meta
          name="description"
          content="AST Constrctions a name recognized as a pioneer in construction and the development projects in Canada. We have a unique business model, with proven expertise and innovative thinking, project and cost management. We are focused on delivering high-quality work within budgeted time and cost, as evident in the various accolades and repeat business."
        />
        <meta
          name="keywords"
          content="AST Construction, Gallery, Building , Basement, kitchen, bathroom, construction "
        />
        <meta name="author" content="Harleen Grover - Best Web Dealers" />
        <meta name="theme-color" content="#698EDC" />
        <meta name="msapplication-navbutton-color" content="#698EDC" />
        <meta name="apple-mobile-web-app-status-bar-style" content="#698EDC" />
        <meta
          property="og:description"
          content="AST Constrctions a name recognized as a pioneer in construction and the development projects in Canada. We have a unique business model, with proven expertise and innovative thinking, project and cost management. We are focused on delivering high-quality work within budgeted time and cost, as evident in the various accolades and repeat business."
        />
        <meta property="og:site_name" content="" />
        <meta property="og:title" content="Gallery | AST Construction" />
        <meta property="og:image" content={buildingDream} />
        <meta name="csrf-token" content="" />
        <meta name="msapplication-TileColor" content="#698EDC" />
        <meta name="msapplication-TileImage" content="" />
        <meta name="theme-color" content="#698EDC" />
        <meta http-equiv="cache-control" content="max-age=0" />
        <meta http-equiv="cache-control" content="no-cache" />
        <meta http-equiv="pragma" content="no-cache" />
      </Helmet>
      <div id="internal-banner">
        <div className="clr"></div>
        <div id="banner">
          <h5>.Gallery</h5>
        </div>

        <div className="innerPageContent">
          <div id="serviceUL" className="animateRight">
            <strong>Industrial Buildings</strong>
            <ul>
              <li>&raquo; Spinning Units </li>
              <li>&raquo; Autocorner Plants, Open Ends, Blowroom Expension </li>
              <li>&raquo; Paper Units </li>
              <li>&raquo; Food Agro & Chemicals Units </li>
              <li>&raquo; Tractor Units </li>
              <li>&raquo; Car Assembly Units </li>
              <li>&raquo; Forging Units</li>
              <li>&raquo; Godowns Works </li>
              <li>&raquo; Humidifiction Plants & R.A Trenches</li>
            </ul>
          </div>
          <div id="serviceUL">
            <strong>Commercial & Real Estates Buildings</strong>
            <ul>
              <li>&raquo; Integrated Housings / Housing Complexes </li>
              <li>&raquo; Corporate Offices </li>
              <li>&raquo; High Rise Buildings </li>
            </ul>
            <strong>Roads & Highways</strong>
            <ul>
              <li>&raquo; State & National Highway </li>
              <li>&raquo; Industrial R.C.C & Bitumen Roads </li>
              <li>&raquo; Bridges & Culverts </li>
              <li>&raquo; Highway Protection Walls</li>
            </ul>
          </div>
          <div id="serviceUL" className="animateLeft">
            <strong>Water Sanitation and Sewerage</strong>
            <ul>
              <li>&raquo; Providing and Installing O.H.S.R </li>
              <li>&raquo; Water Treatment Plant, S.T.P & E.T.P Plants </li>
              <li>&raquo; Sewrage, Storm & Water Supply Pipe Works</li>
            </ul>
            <strong>Power Transmission Tower</strong>
            <ul>
              <li>&raquo; 66 KV Substations </li>
              <li>&raquo; Tower Foundation and Erection </li>
              <li>&raquo; DG Foundation</li>
            </ul>
            <strong>Educational & Judicial Building</strong>
            <ul>
              <li>&raquo; Institutional & School Buildings </li>
              <li>&raquo; District Adminstration Building</li>
            </ul>
          </div>
          <div className="clr"></div>
        </div>
      </div>
    </>
  );
}

import Fogg from "../images/fogg.png";
export default function Footer() {
  return (
    <>
      <h1 id="tagline" data-aos="fade-up" data-aos-duration="5000">
        Our entire sister concerns are managed by
        <br /> professionally technocrat partners.
      </h1>
      <iframe
        src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d1285327.5806495496!2d-113.9074264!3d51.0086584!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xa47d8597c7726e59%3A0xd8f2349767282092!2sAst%20constructions%20Ltd!5e0!3m2!1sen!2sin!4v1706135841631!5m2!1sen!2sin"
        width="100%"
        height="250"
        allowFullScreen="allowFullScreen"
        loading="lazy"
        referrerPolicy="no-referrer-when-downgrade"
      ></iframe>
      <footer>
        <img src={Fogg} />
      </footer>
    </>
  );
}

import Nav from "./Nav";
import Popup from "reactjs-popup";
import "reactjs-popup/dist/index.css";

export default function header() {
  // const [state, setstate] = useState([]);
  function reachus() {}
  return (
    <>
      <header>
        <a href="tel:+1 (587) 720-2244">+1 (587) 720-2244</a>
        <Popup trigger={<button> Get a quote</button>} position="bottom">
          <h1>Get a quote</h1>
          <br />
          <form onSubmit={reachus}>
            <label>
              .name
              <input type="text" name="name" required />
            </label>
            <br />
            <label>
              .contact No.
              <input type="text" name="tel" required />
            </label>
            <br />
            <label>
              .email id
              <input type="text" name="email" required />
            </label>
            <br />
            <input type="submit" value=".get a quote" />
          </form>
        </Popup>

        <div className="clr"></div>
      </header>
      <Nav />
    </>
  );
}

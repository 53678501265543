import buildingDream from "../images/logo-ast-construction.png";
import { Helmet } from "react-helmet";
import basementpic from "../images/products/basement.png";
import kitchenpic from "../images/products/kitchen.png";
import washroompic from "../images/products/washroom.png";
export default function Services() {
  return (
    <>
      <Helmet>
        <title>Services | AST Constructions</title>
        <meta
          name="description"
          content="AST Constrctions a name recognized as a pioneer in construction and the development projects in Canada. We have a unique business model, with proven expertise and innovative thinking, project and cost management. We are focused on delivering high-quality work within budgeted time and cost, as evident in the various accolades and repeat business."
        />
        <meta
          name="keywords"
          content="AST Construction, Services, Building , Basement, kitchen, bathroom, construction "
        />
        <meta name="author" content="Harleen Grover - Best Web Dealers" />
        <meta name="theme-color" content="#698EDC" />
        <meta name="msapplication-navbutton-color" content="#698EDC" />
        <meta name="apple-mobile-web-app-status-bar-style" content="#698EDC" />
        <meta
          property="og:description"
          content="AST Constrctions a name recognized as a pioneer in construction and the development projects in Canada. We have a unique business model, with proven expertise and innovative thinking, project and cost management. We are focused on delivering high-quality work within budgeted time and cost, as evident in the various accolades and repeat business."
        />
        <meta property="og:site_name" content="" />
        <meta property="og:title" content="Services | AST Construction" />
        <meta property="og:image" content={buildingDream} />
        <meta name="csrf-token" content="" />
        <meta name="msapplication-TileColor" content="#698EDC" />
        <meta name="msapplication-TileImage" content="" />
        <meta name="theme-color" content="#698EDC" />
        <meta http-equiv="cache-control" content="max-age=0" />
        <meta http-equiv="cache-control" content="no-cache" />
        <meta http-equiv="pragma" content="no-cache" />
      </Helmet>
      <div id="internal-banner">
        <div className="clr"></div>
        <div id="banner">
          <h5>.services</h5>
        </div>

        <div className="innerPageContent" id="servicesPage">
          <h2>Basement Renovations by AST Construction Limited</h2>
          <img src={basementpic} />
          <p>
            At AST Construction Limited, we understand that basement renovations
            can vary widely in complexity, but our commitment to delivering
            beautiful, quality results remains unwavering. Whether we're
            transforming an unfinished basement into a functional living space
            or renovating an existing basement to enhance its appeal and
            functionality, we approach each project with meticulous attention to
            detail and a focus on exceeding our clients' expectations.
          </p>
          <p>
            Ready to transform your basement into a stunning, functional space
            that reflects your style and enhances your lifestyle? Contact AST
            Construction Limited today to discuss your renovation project and
            discover how we can turn your vision into reality.
          </p>
          <p>
            With AST Construction Limited, your basement renovation journey
            begins and ends with unparalleled quality, attention to detail, and
            a dedication to delivering exceptional results every time.
          </p>
          <br />
          <br />
          <br />
          <h2>Reimagining Your Home: Unveiling the Potential of Every Space</h2>
          <p>
            At AST Construction Limited, we believe that every room in your
            house holds untapped potential waiting to be discovered, even the
            often-overlooked toilet. Our goal is to help you see beyond the
            ordinary and explore the extraordinary possibilities of transforming
            this essential space into a sanctuary of comfort and luxury.
          </p>
          <img src={washroompic} />
          <h5>Sleek Designs, Smart Solutions:</h5>
          <p>
            Our architectural and interior designers collaborate seamlessly to
            optimize the use of space in your toilet area, ensuring a cohesive
            layout that is both intuitive and attractive. From lighted mirrors
            that illuminate your reflection to custom vanities that maximize
            storage, every detail is meticulously crafted to enhance
            functionality without compromising on style.
          </p>
          <h5>Your Personal Retreat Awaits:</h5>
          <p>
            Transform your toilet into a haven of tranquility and sophistication
            with AST Construction Limited. Let us guide you through the
            possibilities, from innovative features to stylish designs, and
            create a space that exceeds your expectations. Contact us today to
            embark on a journey to a toilet that is as functional as it is
            fabulous.
          </p>
          <br />
          <br />
          <br />
          <h2>Elevate Your Kitchen: Transformative Renovations in Calgary</h2>
          <p>
            In the heart of every home lies the kitchen—a space where memories
            are made and flavors are shared. At AST Construction Limited, we
            understand the importance of this pivotal room, and our mission is
            to elevate its essence through meticulous renovations tailored to
            your desires.
          </p>
          <img src={kitchenpic} />
          <h5>Crafting Your Vision:</h5>
          <p>
            Your dream kitchen begins with your vision. Our team of designers
            collaborates closely with you during the planning phase, ensuring
            every detail reflects your style and functionality needs. Whether
            you envision a sleek, modern aesthetic or a cozy, rustic charm, we
            bring your vision to life with precision and passion.
          </p>
          <h5>Unparalleled Upgrades:</h5>
          <p>
            We believe in the power of transformation. From floor to ceiling, we
            offer unparalleled upgrades that elevate your kitchen to new heights
            of beauty and functionality. Say goodbye to outdated cupboards and
            hello to custom-made MDF or natural wood cabinets, expertly crafted
            to suit your style and storage needs. Enhance the ambiance with
            stylish lighting fixtures, artisanal backsplashes, and durable
            butcher block countertops that blend form with function seamlessly.
          </p>
          <h5>Your Culinary Haven Awaits:</h5>
          <p>
            Step into a world of culinary bliss with AST Construction Limited.
            Let us turn your kitchen renovation dreams into reality, creating a
            space that not only enhances the beauty of your home but also
            enriches your daily life. Contact us today to begin your journey to
            a kitchen that's as functional as it is fabulous.
          </p>
          <p>
            With AST Construction Limited, your kitchen renovation in Calgary
            isn't just a project—it's a transformative experience that elevates
            the heart of your home to extraordinary new heights.
          </p>
          <div className="clr"></div>
          <br /> <br /> <br /> <br /> <br /> <br />
        </div>
      </div>
    </>
  );
}
